export default [
    {
        type: "selection",
        width: 38,
        align: "center",
    },

    {
        title: 'Definition Name',
        key: 'Name',
        minWidth: 80,
    },

    {
        title: 'Text',
        key: 'Text',
        minWidth: 80,
    }
]
